<template>
    <div class="politique">
        <b>
            Mentions légales et politique de confidentialité
        </b>
        <p>
            Baptiste Tareal, soucieux des droits des individus, notamment au regard des traitements automatisés et dans une volonté de transparence avec ses clients,
            a mis en place une politique reprenant l'ensemble de ces traitement, des finalités poursuivies par ces derniers ainsi que des moyens d'actions à la
            disposition des individus afin qu'ils puissent au mieux exercer leurs droits.
            Pour toute information complémentaires sur la protection des données personelles, nous vous invitons à consulter le site : https://www.cnil.fr/
        </p>
        <p>
            La poursuite de la navigation sur ce site vaut acceptation sans réserve des dispositions et conditions d'utilisation qui suivent. La version actuellement
            en ligne de ces conditions d'utilisation est la seule oppsable pendant toute la durée d'utilisation du site et jusqu'à ce qu'une nouvelle version la remplace.
        </p>
        <b>
            Article 1 - Mentions légales
        </b>
        <p>
            1.1 Site (ci-après "le site") : www.baptistetareal.com
        </p>
        <p>
            1.2 Éditeur (ci-après désigné "l'Éditeur") : Baptiste Tareal, joignable au numéro de téléphone suivant : 09.84.12.69.02, 
            à l'adresse e-mail suivante : baptistetareal@gmail.com, ou à son adresse professionnelle située au 15 rue du Faubourg Saint-Vincent, 45000 ORLÉANS.
        </p>
        <p>
            1.3 Hébergeur (ci-après "l'hébergeur") : le site est hébergé par OVH, dont le siège social est situé 2 rue
            Kellermann, 49100 Roubaix.
        </p>
        <b>
            Article 2 - Accès au site
        </b>
        <p>
            L'accès au site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas utiliser ce site et les informations ou 
            données qui y figurent à des fins commerciales, politique, publicitaires et pour toute forme de sollicitation commerciale et notamment l'envoi de
            courriers électroniques non sollicités.
        </p>
        <b>
            Article 3 - Contenu du site
        </b>
        <p>
            Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications
            informatique qui pourraient être utilisées pour faire fonctionner ce site et plus généralement tous les éléments reproduits ou utilisés sur le site 
            sont protégés par les lois en vigueur au titre de la propriété intellectuelle.
        </p>
        <p>
            Ils sont la proprété pleine et entière de l'éditeur ou de ses partenaires. Toute reproduction, représentation, utilisation ou adaptation, sous quelque 
            forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l'accord préalable et écrit de l'éditeur, 
            sont strictement interdites. Le fait pour l'éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne 
            vaut pas acceptation desdites utilisations et renonciation aux poursuites.
        </p>
        <b>
            Article 4 - Gestion du site
        </b>
        <p>
            Pour la bonne gestion du site, l'éditeur pourra à tout moment :<br/>
            - suspendre, interrompre ou limiter l'accés à tout ou partie du site, réserver l'accés au site, ou à certaines parties du site,
            à une catégorie déterminée d'internautes.<br/>
            - supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales.<br/>
            - suspendre le site afin de procéder à des mises à jour.
        </p>
        <b>
            Article 5 - Responsabilités
        </b>
        <p>
            La responsabilité de l'éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l'accès au site ou à une 
            de ses fonctionnalités. Le matériel de connexion au site que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes les mesures appropriées
            pour protéger votre matériel et vos propres données notamment d'attaques virales par Internet.<br/>
            Vous êtes par ailleurs seul responsable des sites et données que vous consultez.
        </p>
        <p>
            L'éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :<br/>
            - du fait de l'usage du site ou de tout service accessible via Internet.<br/>
            - du fait du non-respect par vous des présentes conditions générales.
        </p>
        <p>
            L'éditeur n'est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du site
            et vous renoncez à toute action contre lui de ce fait.<br/>
            Si l'éditeur venait à faire l'objet d'une procédure pénale amiable ou judiciaire en raison de votre utilisation du site, il pourra se retourner contre vous pour
            obtenir l'indemnisation de tout préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.
        </p>
        <b>
            Article 6 - Liens hypertextes
        </b>
        <p>
            La mise en place par les utilisateurs de tous liens hypertextes vers tout ou partie du site est autorisée par l'éditeur. Tout lien devra être retiré sur simple
            demande de l'éditeur.<br/>
            Toute information accessible via un lien vers d'autres sites n'est pas publiée par l'éditeur.
            L'éditeur ne dispose d'aucun droit sur le contenu présent sur ledit lien.
        </p>
        <b>
            Article 7 - Collecte et protection des données
        </b>
        <p>
            Vos données sont collectées par l'éditeur dans le cas d'une utilisation du formulaire de contact seulement. Une donnée à caractère personnel désigne toute
            information concernant une personne physique identifiée ou identifiable (personne concernée). Est réputée identifiable une personne qui peut être 
            identifiée, directement ou indirectement, notamment par référence à un nom, un numéro d'identification ou à un ou plusieurs éléments spécifiques,
            propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale.<br/>
            Les informations personnelles pouvant être recueillies sur le site sont utilisées par l'éditeur uniquement pour la gestion des relations avec vous.
        </p>
        <p>
            Les données personnelles collectées sont les suivantes :<br/>
            - adresse mail<br/>
            - numéro de téléphone<br/>
            - âge<br/>
        </p>
        <b>
            Article 8 - Droit d'accès, de rectification et de déréférencement de vos données
        </b>
        <p>
            En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des
            droits suivants :<br/>
            - le droit d'accès: ils peuvent exercer leur droit d'accès, pour connaître les données personnelles les concernant,
            en écrivant à l'adresse électronique ci-dessous mentionnée. Dans ce cas, avant la mise en œuvre de ce droit,
            la Plateforme peut demander une preuve de l'identité de l'utilisateur afin d'en vérifier l'exactitude.<br/>
            - le droit de rectification: si les données à caractère personnel détenues par la Plateforme sont inexactes, ils
            peuvent demander la mise à jour des informations.<br/>
            - le droit de suppression des données : les utilisateurs peuvent demander la suppression de leurs données à
            caractère personnel, conformément aux lois applicables en matière de protection des données.<br/>
            - le droit à la limitation du traitement : les utilisateurs peuvent de demander à la Plateforme de limiter le
            traitement des données personnelles conformément aux hypothèses prévues par le RGPD.<br/>
            - le droit de s'opposer au traitement des données : les utilisateurs peuvent s'opposer à ce que leurs données soient
            traitées conformément aux hypothèses prévues par le RGPD.<br/>
            - le droit à la portabilité : ils peuvent réclamer que la Plateforme leur remette les données personnelles qu'ils
            ont fournies pour les transmettre à une nouvelle Plateforme.
        </p>
        <p>
            Vous pouvez exercer ce droit en nous contactant, par email à l'adresse suivante : baptistetareal@gmail.com
        </p>
        <p>
            Toute demande doit être accompagnée de la photocopie d'un titre d'identité en cours de validité signé et faire
            mention de l'adresse à laquelle l'éditeur pourra contacter le demandeur. La réponse sera adressée dans le mois
            suivant la réception de la demande. Ce délai d'un mois peut être prolongé de deux mois si la complexité de la
            demande et/ou le nombre de demandes l'exigent.
        </p>
        <p>
            De plus, et depuis la loi n°2016-1321 du 7 octobre 2016, les personnes qui le souhaitent, ont la possibilité
            d'organiser le sort de leurs données après leur décès. Pour plus d'information sur le sujet, vous pouvez
            consulter le site Internet de la CNIL : https://www.cnil.fr/.
        </p>
        <p>
            Les utilisateurs peuvent aussi introduire une réclamation auprès de la CNIL sur le site de la CNIL : https://www.cnil.fr.<br/>
            Nous vous recommandons de nous contacter dans un premier temps avant de déposer une réclamation auprès de la CNIL,
            car nous sommes à votre entière disposition pour régler votre problème.
        </p>
        <b>
            Article 9 - Utilisation des données
        </b>
        <p>
            Les données personnelles collectées à travers le formulaire de contact ont pour unique objectif de permettre à la
            Plateforme de répondre aux questions et demandes des utilisateurs. La base légale de ce traitement est l'intérêt
            légitime de la plateforme à communiquer efficacement avec ses utilisateurs.<br/>
            Aucun autre traitement, tel que la personnalisation des services, n'est effectué à partir des informations collectées via ce formulaire.
        </p>
        <b>
            Article 10 - Politique de conservation des données
        </b>
        <p>
            Les données collectées via le formulaire de contact ne sont pas conservées sur la Plateforme. Elles sont uniquement
            transmises par e-mail afin de permettre une réponse aux questions et demandes des utilisateurs. Une fois l'e-mail
            envoyé, aucune donnée n'est stockée sur nos serveurs. Ces informations peuvent être conservées dans notre système de
            messagerie pour la durée nécessaire à la gestion de la relation utilisateur, et afin de satisfaire aux obligations légales ou réglementaires.
        </p>
        <b>
            Article 11 - Partage des données personnelles avec des tiers
        </b>
        <p>
            Les données collectées via le formulaire de contact ne sont pas partagées avec des tiers. Elles sont
            exclusivement utilisées pour permettre à la Plateforme de répondre aux questions et demandes des utilisateurs. Cependant, ces
            données sont transmises par e-mail, ce qui implique qu'elles pourraient être stockées par notre fournisseur de
            services de messagerie (par exemple, Gmail) conformément à sa propre politique de confidentialité.
        </p>
        <b>
            Article 12 - Cookies
        </b>
        <p>
            Qu'est-ce qu'un "Cookie" ?<br/>
            Un "Cookie" ou traceur est un fichier électronique déposé sur un terminal
            (ordinateur, tablette, smartphone....) et lu par exemple lors de la consultation d'un site internet, de la
            lecture d'un courrier électronique, de l'installation ou de l'utilisation d'un logiciel ou d'une application
            mobile et ce, quel que soit le type de terminal utilisé (source: https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi).<br/>
            Ce site n'utilise pas de cookies ou de traceurs pour collecter ou stocker des données des utilisateurs.
        </p>
        <b>
            Article 13 - Loi applicable
        </b>
        <p>
            Les présentes conditions d'utilisation du site sont régies par la
            loi française et soumises à la compétence des tribunaux du siège social de l'éditeur, sous réserve d'une
            attribution de compétence spécifique découlant d'un texte de loi ou réglementaire particulier.
        </p>
        <b>
            Article 14 - Contact
        </b>
        <p>
            Contactez-nous pour toute question, information sur les produits présentés sur le site, ou concernant le site
            lui-même, vous pouvez laisser un message à l'adresse suivante: baptistetareal@gmail.com
        </p>
    </div>
</template>

<style scoped>
.politique {
    padding-top: 5%;
}

@media screen and (min-width: 1000px) and (max-width: 1600px) {
  .politique {
    padding-top: 8%;
  }
}

@media screen and (max-width: 999px) {
  .politique {
    padding-top: 16%;
  }
}
</style>