<template>
    <section id="shop" class="shop section">
        <h1>{{ getTranslation('shop-title')}}</h1>
        <div class="shop-carousel rounded">
            <img v-for="(image, index) in images" 
                :src="image" 
                :key="index" 
                :class="{ 'active': index === activeImage }" 
                alt="Image du shop" />
        </div>
        <p>{{ getTranslation('shop-1')}}</p>
        <p>{{ getTranslation('shop-2')}}</p>
        <div class="sculpture-images">
            <img class="rounded" src="~@/assets/images/sculpture1.jpeg" alt="sculpture">
            <img class="rounded" src="~@/assets/images/sculpture3.jpg" alt="sculpture">
            <img class="rounded" src="~@/assets/images/sculpture5.jpeg" alt="sculpture">
            <img class="rounded" src="~@/assets/images/sculpture7.jpg" alt="sculpture">
        </div>
        <b>{{ getTranslation('shop-3')}}</b>
        <p>{{ getTranslation('shop-4')}}</p>
    </section>
</template>
  
<script>
import { TranslationService } from '@/services/TranslationService';

export default {
    name: 'ShopComponent',
    data() {
        return {
            images: [require('@/assets/images/shop1.jpeg'), require('@/assets/images/shop2.jpeg')],
            activeImage: 0
        };
    },
    created() {
        setInterval(() => {
            this.activeImage = (this.activeImage + 1) % this.images.length;
        }, 5000);
    },
    methods: {
        getTranslation(key) {
            return TranslationService.get(key);
        }
    }
};
</script>
  
<style scoped>
.shop {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2%;
    position: relative;
}

.shop::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: black;
}

.shop h1 {
    text-align: center;
}

.shop-carousel {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 1%;
}

.shop-carousel img {
    flex-shrink: 0;
    width: 100%;
    height: auto;
    transition: opacity 1s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.shop-carousel img.active {
    opacity: 1;
    position: relative;
}

.sculpture-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;
}

.sculpture-images img {
    flex: 1 1 20%;
    max-width: 20%;
    margin-top: 30px;
}

@media (max-width: 1000px) {
    .sculpture-images img {
        flex: 1 1 45%;
        max-width: 45%;
    }
}
</style>
  