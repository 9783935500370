import en from '@/assets/languages/en.json';
import fr from '@/assets/languages/fr.json';

const languages = { en, fr };

export const TranslationService = {
    currentLanguage: localStorage.getItem('appLanguage') || 'fr',

    changeLanguage(language) {
      this.currentLanguage = language;
      localStorage.setItem('appLanguage', language);
    },

    get(key) {
      return languages[this.currentLanguage][key] || key;
    }
};