import axios from 'axios';

export const sendContactForm = (formData) => {
  return axios.post(process.env.VUE_APP_BACKEND_URL + '/v1/contact/submit-form', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(response => response.data)
  .catch(error => Promise.reject(error));
};