<template>
    <section id="apropos" class="section apropos">
        <div class="apropos-texte">
            <h1>{{ getTranslation('about-title') }}</h1>
            <p>{{ getTranslation('about-1') }}</p>    
            <p>{{ getTranslation('about-2') }}</p>   
            <p>{{ getTranslation('about-3') }}</p>   
        </div>
        <div class="apropos-image">
            <img src="~@/assets/images/baptiste.jpg" alt="Baptiste Tareal" />
        </div>
    </section>
</template>

<script>
    import { TranslationService } from '@/services/TranslationService';

    export default {
        name: 'AProposComponent',

        methods: {
            getTranslation(key) {
                return TranslationService.get(key);
            }
        }
    }
</script>

<style scoped>
.apropos {
    display: flex;
    flex-direction: row;
}

.apropos-texte, .apropos-image {
    flex: 1;
    box-sizing: border-box;
}
.apropos-texte {
    padding: 0 50px 0 0;
}

.apropos-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1000px) {
  .apropos {
    flex-direction: column;
  }
  .apropos-texte, .apropos-image {
    flex: 100%;
  }
  .apropos-texte {
    padding: 0 0 20px 0;
}
  .apropos-image img {
    object-fit: contain;
  }
}

@media (min-width: 1201px) {
  .apropos-image {
    max-width: 400px;
  }
}
</style>
