<template>
     <div id="accueil" class="accueil">
        <img src="~@/assets/images/accueil.jpg" alt="accueil">
     </div>
</template>

<script>
    export default {
        name: 'AccueilComponent',
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll() {
                const header = document.getElementById('header');
                if (window.scrollY > 100) {
                  header.classList.add('header-scrolled');
                } else {
                  header.classList.remove('header-scrolled');
                }
            },
        },
    };
</script>

<style scoped>
    .accueil {
        position: relative;
        color: #fff;
    }

    .accueil img {
        height: 100vh;
        width: 100%;
        object-fit: cover;
    }

    .accueil-content {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .accueil-title {
        color: #fff;
        font-weight: 600;
        font-size: 3rem;
        text-transform: uppercase;
    }

    @media (max-width: 1100px) {
        .accueil img {
            width: 100%;
            height: auto;
    }
}
</style>