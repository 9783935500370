<template>
    <section id="creation" class="creation section">
        <div class="creation-entete">
            <h1>{{ getTranslation('creations-title')}}</h1>
            <a href="https://www.instagram.com/baptistetareal/" target="_blank">
                <img src="@/assets/images/instagram.png" alt="Instagram" />
            </a>
        </div>
        <div class="creation-content">
            <div v-for="(post, index) in instagramPosts" :key="index" class="image-box" @click="openModal(index)">
                <img v-if="post.media_type === 'IMAGE'" :src="post.media_url" alt="Instagram Image">
                <video v-else-if="post.media_type === 'VIDEO'" :src="post.media_url"></video>
            </div>
        </div>

        <div v-if="showModal" class="modal">
            <span class="close" @click="closeModal">&times;</span>
            <div class="modal-content-wrapper">
                <img v-if="instagramPosts[currentIndex].media_type === 'IMAGE'" :src="instagramPosts[currentIndex].media_url" class="modal-content">
                <video v-else-if="instagramPosts[currentIndex].media_type === 'VIDEO'" autoplay :src="instagramPosts[currentIndex].media_url"
                    class="modal-content"></video>
            </div>
            <span class="prev" @click="prevImage">&#10094;</span>
            <span class="next" @click="nextImage">&#10095;</span>
        </div>
    </section>
</template>
  
<script>
import { getInstagramPosts } from '@/services/InstagramService';
import { TranslationService } from '@/services/TranslationService';

export default {
    data() {
        return {
            instagramPosts: [],
            showModal: false,
            currentIndex: 0
        };
    },
    methods: {
        openModal(index) {
            this.showModal = true;
            this.currentIndex = index;
        },
        closeModal() {
            this.showModal = false;
        },
        nextImage() {
            this.currentIndex = (this.currentIndex + 1) % this.instagramPosts.length;
        },
        prevImage() {
            this.currentIndex = (this.currentIndex - 1 + this.instagramPosts.length) % this.instagramPosts.length;
        },
        updateContainerHeight() {
            this.$nextTick(() => {
                const imageBoxes = document.querySelectorAll('.image-box');
                if (imageBoxes.length === 0) return;

                const boxWidth = imageBoxes[0].clientWidth; 

                imageBoxes.forEach(box => {
                    box.style.height = `${boxWidth}px`; 
                });

                const gridGap = 5;
                const numRows = 2;
                const containerHeight = (boxWidth * numRows) + (gridGap * (numRows - 1));
                document.querySelector('.creation-content').style.height = `${containerHeight}px`;
            });
        },
        getTranslation(key) {
            return TranslationService.get(key);
        }
    },
    mounted() {
        getInstagramPosts()
            .then(data => {
                this.instagramPosts = data;
                let loadedImages = 0;

                this.$nextTick(() => {
                    const images = document.querySelectorAll('.image-box img');

                    images.forEach(image => {
                        image.addEventListener('load', () => {
                            loadedImages++;
                            if (loadedImages === images.length) {
                                this.updateContainerHeight();
                            }
                        });
                    });
                });
            })
            .catch(error => {
                console.error('Erreur d\'appel API:', error);
            });
        window.addEventListener('resize', this.updateContainerHeight);
        this.updateContainerHeight();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateContainerHeight);
    }
};

</script>
    
<style scoped>
.creation {
    padding-bottom: 3%;
}

.creation::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: black;
}

.creation-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: row;
    grid-gap: 5px;
    overflow-y: auto;
    border: 1px solid #ccc;
    margin: auto;
}

.creation-entete {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.creation-entete img {
    height: 50px;
    width: auto;
}

.image-box {
    position: relative;
    cursor: pointer;
    padding: 3px;
}

.image-box img,
.image-box video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}

.creation-content::-webkit-scrollbar {
    width: 10px;
}

.creation-content::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}

.creation-content::-webkit-scrollbar-thumb:hover {
    background: grey;
}

.creation-content::-webkit-scrollbar-track {
    background: transparent;
}

.creation-content::-webkit-scrollbar-button {
    display: none;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
}

.modal-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 80%;
}

.modal-content {
    max-height: 100%;
    width: 100%;
    object-fit: contain;
}

.close,
.next,
.prev {
    position: absolute;
    color: #fff;
    font-size: 4em;
    cursor: pointer;
    z-index: 2;
}

.close {
    top: 10%;
    right: 10%;
}

.next {
    right: 10%;
    bottom: 50%;
}

.prev {
    left: 10%;
    bottom: 50%;
}

@media (max-width: 650px) {
    .creation-content {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 651px) and (max-width: 1000px) {
    .creation-content {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1001px) and (max-width: 1600px) {
    .creation-content {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>