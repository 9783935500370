<template>
	<div>
		<HeaderComponent />
		<router-view />
		<FooterComponent />
		<ConsentComponent />
	</div>
</template>
  
<script>
	import HeaderComponent from './components/HeaderComponent.vue'
	import FooterComponent from './components/FooterComponent.vue'
	import ConsentComponent from './components/ConsentComponent.vue'

	export default {
		name: 'App',
		components: {
			HeaderComponent,
			FooterComponent,
			ConsentComponent
		}
	}
</script>