<template>
    <footer class="footer">
        <div class="footer-left">
            <img src="@/assets/images/footer-info.png" alt="Informations" />
        </div>
        <div class="footer-center">
            <div class="social-media">
                <a href="https://www.facebook.com/baptistetareal" target="_blank"><img src="@/assets/images/facebook.png"
                        alt="Facebook" /></a>
                <a href="https://www.instagram.com/baptistetareal/" target="_blank"><img src="@/assets/images/instagram.png"
                        alt="Instagram" /></a>
            </div>
            <div class="footer-privacy">
                <a href="/privacy-policy">Politique de Confidentialité</a>
            </div>
        </div>
        <div id="footer-plan" class="footer-right rounded"></div>
    </footer>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let map;

const initializeMap = () => {
    if (map) {
        map.remove();
    }
    map = L.map('footer-plan').setView([47.90565, 1.91389], 16);
    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19
    }).addTo(map);
    let defaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconAnchor: [12, 41]
    });
    L.marker([47.90565, 1.91389], { icon: defaultIcon }).addTo(map);
    setTimeout(() => map.invalidateSize(), 100);
}

onMounted(() => {
    initializeMap();
    window.addEventListener('resize', initializeMap);
});

onUnmounted(() => {
    window.removeEventListener('resize', initializeMap);
});
</script>
  
<style scoped>
.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1%;
    background-color: rgb(49, 49, 49);
}

.footer-left,
.footer-right {
    flex: 2;
}

.footer-center {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.social-media,
.footer-privacy {
    flex:1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.footer-left img,
.footer-plan img {
    max-width: 100%;
    max-height: 100%;
}

.footer-center img {
    width: 50px;
    margin: 0 10px 0 10px;
}

.footer-privacy a {
    color: white;
    text-decoration: none;
    font-family: Helvetica;
    font-size: 0.9rem;
}

.footer-privacy a:hover {
    color: white;
}


@media (max-width: 1000px) {
    .footer {
        flex-direction: column-reverse;
        margin: 10% 0;
    }

    .footer-center {
        padding: 10%;
    }
    
    .footer-right, #footer-plan {
        min-height: 50vh;
    }
}

@media (min-width: 600px) and (max-width: 1000px) {
    .footer {
        padding: 3% 10% 1% 10%;
    }

}
</style>