<template>
	<div class="between-section" :style="`background-image: url(${image})`">
		<div class="filtre"></div>
	</div>
</template>
  
<script>
	export default {
		name: 'BetweenSectionComponent',
		props: ['image'],
	};
</script>
  
<style scoped>
    .between-section {
		position: relative;
		padding: 12rem 0;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		background-position: center center;
    }
</style>