import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import HomeView from './views/HomeView.vue'
import PrivacyPolicyView from './views/PrivacyPolicyView.vue'
import '@/assets/css/global.css'
import Scrollactive from 'vue-scrollactive';

const routes = [
  { path: '/', component: HomeView },
  { path: '/privacy-policy', component: PrivacyPolicyView },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

createApp(App).use(router).use(Scrollactive).mount('#app')
