<template>
	<div>
		<AccueilComponent />
		<AProposComponent />
		<BetweenSectionComponent :image="require('@/assets/images/tatooing.jpg')" />
		<CreationComponent />
		<BetweenSectionComponent :image="require('@/assets/images/tatooing.jpg')" />
		<ShopComponent />
		<ContactComponent />
	</div>
</template>
  
<script>
	import AccueilComponent from '@/components/home/AccueilComponent.vue';
	import AProposComponent from '@/components/home/AProposComponent.vue';
	import BetweenSectionComponent from '@/components/home/BetweenSectionComponent.vue';
	import CreationComponent from '@/components/home/CreationComponent.vue';
	import ShopComponent from '@/components/home/ShopComponent.vue';
	import ContactComponent from '@/components/home/ContactComponent.vue';

	export default {
		components: {
			AccueilComponent,
			AProposComponent,
			BetweenSectionComponent,
			CreationComponent,
			ShopComponent,
			ContactComponent
		}
	}
</script>