<template>
    <section id="contact" class="contact">
        <div class="contact-images">
            <div class="contact-content">
                <h1>{{ getTranslation('contact-title') }}</h1>
                <p>{{ getTranslation('contact-description') }}</p>
                <form class="form" @submit.prevent="submitForm">
                    <div class="form-row">
                        <div class="form-field">
                            <label>{{ getTranslation('first-name') }}</label>
                            <input type="text" v-model="form.prenom" />
                            <span v-if="errors.prenom">{{ errors.prenom }}</span>
                        </div>
                        <div class="form-field">
                            <label>{{ getTranslation('last-name') }} </label>
                            <input type="text" v-model="form.nom" />
                            <span v-if="errors.nom">{{ errors.nom }}</span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-field">
                            <label>{{ getTranslation('phone-number') }}</label>
                            <input type="text" v-model="form.telephone" />
                            <span v-if="errors.telephone">{{ errors.telephone }}</span>
                        </div>
                        <div class="form-field">
                            <label>{{ getTranslation('email') }}</label>
                            <input type="text" v-model="form.email" />
                            <span v-if="errors.email">{{ errors.email }}</span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-field">
                            <label>{{ getTranslation('age') }}</label>
                            <input type="text" v-model="form.age" />
                            <span v-if="errors.age">{{ errors.age }}</span>
                        </div>
                        <div class="form-field">
                            <label>{{ getTranslation('type') }}</label>
                            <select v-model="form.type">
                                <option value="Cover">{{ getTranslation('type-1') }}</option>
                                <option value="Nouveau tatouage">{{ getTranslation('type-2') }}</option>
                            </select>
                            <span v-if="errors.type">{{ errors.type }}</span>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-field">
                            <label>{{ getTranslation('placement') }}</label>
                            <input type="text" v-model="form.emplacement" />
                            <span v-if="errors.emplacement">{{ errors.emplacement }}</span>
                        </div>
                        <div class="form-field">
                            <label>{{ getTranslation('image') }}</label>
                            <input type="file" ref="fileInput" @change="handleFileUpload" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-field-radio">
                            <label>{{ getTranslation('client') }}</label>
                            <div class="radio-row">
                                <div class="input-radio">
                                    <input type="radio" value="Oui" v-model="form.client" id="oui">{{ getTranslation('yes') }}
                                </div>
                                <div class="input-radio">
                                    <input type="radio" value="Non" v-model="form.client" id="non">{{ getTranslation('no') }}
                                </div>
                            </div>
                            <span v-if="errors.client">{{ errors.client }}</span>
                        </div>
                        <div class="form-field-radio">
                            <label>{{ getTranslation('contact-preference') }}</label>
                            <div class="radio-row">
                                <div class="input-radio">
                                    <input type="radio" value="Mail" v-model="form.preference">{{ getTranslation('contact-preference-1') }}
                                </div>
                                <div class="input-radio">
                                    <input type="radio" value="Téléphone" v-model="form.preference">{{ getTranslation('contact-preference-2') }}
                                </div>
                            </div>
                            <span v-if="errors.preference">{{ errors.preference }}</span>
                        </div>
                    </div>
                    <label>{{ getTranslation('description') }}</label>
                    <textarea v-model="form.description"></textarea>
                    <input type="text" v-model="form.ville" class="ville" />
                    <button type="submit" :disabled="isLoading">
                        {{ isLoading ? getTranslation('sending') : getTranslation('send') }}
                        <span v-if="isLoading" class="spinner"></span>
                    </button>
                    <div>
                        <span :class="submitSuccess ? 'success' : 'error'">{{ submitMessage }}</span>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>
  
<script>
import { sendContactForm } from "@/services/ContactService";
import { TranslationService } from "@/services/TranslationService";

export default {
    data() {
        return {
            form: {
                prenom: "",
                nom:"",
                telephone: "",
                email: "",
                age: "",
                type: "",
                emplacement: "",
                client: "",
                preference: "",
                description: "",
                ville: "",
                file: null,
            },
            errors: {
                prenom: "",
                nom:"",
                telephone: "",
                email: "",
                age: "",
                type: "",
                emplacement: "",
                client: "",
                preference: "",
            },
            submitMessage: "",
            submitSuccess: false,
            isLoading: false
        };
    },
    methods: {
        validateEmail(email) {
            const re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            return re.test(email);
        },
        validatePhoneNumber(number) {
            const re = /^[0-9\-+]{9,15}$/;
            return re.test(number);
        },
        async submitForm() {
            if (this.form.ville) {
                this.isLoading = false;
                return;
            }
            this.isLoading = true;
            let isValid = true;
            this.errors = {};
            const validations = [
                {
                    field: "prenom",
                    validator: (prenom) => /^[A-Za-zéèàêâôûùïëç-]+$/.test(prenom),
                    message: TranslationService.get('first-name-invalid'),
                    requiredMessage: TranslationService.get('first-name-missing')
                },
                {
                    field: "nom",
                    validator: (nom) => /^[A-Za-zéèàêâôûùïëç-]+$/.test(nom),
                    message: TranslationService.get('last-name-invalid'),
                    requiredMessage: TranslationService.get('last-name-missing')
                },
                {
                    field: "telephone",
                    validator: this.validatePhoneNumber,
                    message: TranslationService.get('phone-invalid'),
                    requiredMessage: TranslationService.get('phone-missing')
                },
                {
                    field: "email",
                    validator: this.validateEmail,
                    message: TranslationService.get('mail-invalid'),
                    requiredMessage: TranslationService.get('mail-missing')
                },
                {
                    field: "age",
                    validator: (age) => !isNaN(age),
                    message: TranslationService.get('age-invalid'),
                    requiredMessage: TranslationService.get('age-missing')
                },
                {
                    field: "type",
                    message: TranslationService.get('type-invalid'),
                    requiredMessage: TranslationService.get('type-missing')
                },
                {
                    field: "emplacement",
                    message: TranslationService.get('placement-invalid'),
                    requiredMessage: TranslationService.get('placement-missing')
                },
                {
                    field: "client",
                    message: TranslationService.get('client-invalid'),
                    requiredMessage: TranslationService.get('client-missing')
                },
                {
                    field: "preference",
                    message: TranslationService.get('contact-preference-invalid'),
                    requiredMessage: TranslationService.get('contact-preference-missing')
                }
            ];

            validations.forEach(({ field, validator, message, requiredMessage }) => {
                const value = this.form[field];
                if (!value) {
                    this.errors[field] = requiredMessage;
                    isValid = false;
                } else if (validator && !validator(value)) {
                    this.errors[field] = message;
                    isValid = false;
                }
            });
            if (isValid) {
                const formData = new FormData();
                Object.keys(this.form).forEach(key => {
                    formData.append(key, this.form[key]);
                });

                try {
                    await sendContactForm(formData);
                    this.submitMessage = "Mail envoyé";
                    this.submitSuccess = true;
                    this.form = {
                        prenom: "",
                        nom: "",
                        telephone: "",
                        email: "",
                        age: "",
                        type: "",
                        emplacement: "",
                        client: "",
                        preference: "",
                        description: "",
                        file: null,
                    };
                    this.errors = {
                        prenom: "",
                        nom: "",
                        telephone: "",
                        email: "",
                        age: "",
                        type: "",
                        emplacement: "",
                        client: "",
                        preference: "",
                    };
                    this.$refs.fileInput.value = null;
                } catch (error) {
                    this.submitMessage = "Une erreur est survenue lors de l’envoi du mail";
                    this.submitSuccess = false;
                }
            }
            this.isLoading = false;
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (!file) return;

            const fileType = file.type;
            const validImageTypes = ["image/jpeg", "image/png", "image/gif"];

            if (validImageTypes.includes(fileType)) {
                this.form.file = file;
                this.form.fileName = file.name;
            } else {
                alert("Veuillez sélectionner une image valide.");
                this.form.file = null;
                this.form.fileName = null;
                this.$refs.fileInput.value = null;
            }
        },
        getTranslation(key) {
            return TranslationService.get(key);
        }
    },
};
</script>

<style scoped>
.contact-images {
    background: url("@/assets/images/orleans.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
}

.contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    width: 70%;
    padding: 1% 5%;
    margin: 5% 0;
    border-radius: 5px;
}

.contact-content label {
    font-size: 1.2em;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-row {
    display: flex;
    padding: 1% 0;
}

.form-field,
.form-field-radio {
    flex: 1;
    display: flex;
    padding: 0 1%;
    flex-direction: column;
}

input:not([type="radio"]),
select,
textarea {
    width: 100%;
    padding: 1% 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 18px;
}

input:focus,
textarea:focus {
    border-color: rgb(49, 49, 49);
    outline: none;
}

button {
    padding: 10px 0;
    margin: 1% 25% 0 25%;
    background-color: rgb(49, 49, 49);
    color: white;
    border-radius: 5px;
    border: none;
    text-transform: uppercase;
    font-size: 15px;
    cursor: pointer;
}

button:hover {
    background-color: rgb(90, 90, 90);
}

textarea {
    height: 100px;
}

.form-field span,
.form-field-radio span {
    color: red;
}

.radio-row {
    display: flex;
    justify-content: center;
    margin: 5px 0;
}

.input-radio {
    display: flex;
    justify-content: center;
    width: 25%;
}

.success,
.error {
    display: block;
    text-align: center;
    margin-top: 10px;
    font-size: 1.2rem;
}

.success {
    color: green;

}

.error {
    color: red;
}

.ville {
  position: absolute;
  left: -9999px;
}

.spinner {
    display: inline-block;
    border: 0.2em solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 0.2em solid #fff;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5em;
    animation: spin 2s linear infinite;
    vertical-align: middle;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width:1000px) {
    .contact-content {
        margin: 0;
        width: 100%;
        border-radius: 0px;
        padding: 1% 10%;
    }

    .form-row {
        flex-direction: column;
        padding: 0;
    }

    .form-field,
    .form-field-radio {
        padding: 1%;
    }
}
</style>