<template>
    <div id="consent-banner" style="display: none;">
      <p>
        Ce site n'utilise pas de cookies. Les seules données personnelles que nous collectons proviennent du formulaire de contact et sont utilisées uniquement pour traiter votre demande.<br/>
        En continuant à utiliser ce site, vous acceptez notre <a id="privacy-link" href="/privacy-policy">politique de confidentialité</a>.
      </p>
      <button id="consent-button">J'accepte</button>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      const consent = localStorage.getItem("user-consent");
      const banner = document.getElementById("consent-banner");
      const button = document.getElementById("consent-button");
  
      if (!consent) {
        banner.style.display = "block";
      }
  
      button.addEventListener("click", function () {
        localStorage.setItem("user-consent", "accepted");
        banner.style.display = "none";
      });
    }
  }
  </script>
  
  <style scoped>
  #consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px;
    z-index: 1000;
  }
  
  #consent-button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  #privacy-link {
    color: #f1f1f1;
    text-decoration: underline;
  }
  
  #privacy-link:visited {
    color: #f1f1f1;
  }
  </style>
  