<template>
    <header id="header" class="header">
        <p class="logo"><a href="/">Baptiste Tareal</a></p>
        <nav id="navbar" class="navbar" v-if="showNavbar">
            <ul>
                <li v-for="(section, index) in sections" :key="index">
                    <a :href="'#' + sectionsMap[section]" :class="currentSection === section ? 'active' : ''"
                        @click.prevent="navigateToSection(section)">
                        {{ section }}
                    </a>
                </li>
                <li>
                    <div class="language-select">
                        <img src="@/assets/images/franceflag.png" @click="changeLanguage('fr')" alt="Français" />
                        <img src="@/assets/images/gbflag.png" @click="changeLanguage('en')" alt="English" />
                    </div>
                </li>
            </ul>
        </nav>
    </header>
</template>
  
<script>
import { ref } from 'vue';
import { TranslationService } from '@/services/TranslationService';

export default {
    setup() {
        const showNavbar = ref(window.location.pathname === '/');
        const sectionsMap = {
            [TranslationService.get('home')] : 'accueil',
            [TranslationService.get('about')]: 'apropos',
            [TranslationService.get('creations')]: 'creation',
            [TranslationService.get('shop')]: 'shop',
            [TranslationService.get('contact')]: 'contact'
        };
        const sections = Object.keys(sectionsMap);
        const currentSection = ref('accueil');
        const navigateToSection = (section) => {
            const sectionElem = document.getElementById(sectionsMap[section]);
            if (sectionElem) {
                const title = sectionElem.querySelector('h1');
                if (title) {
                    const yOffset = -90;
                    const y = title.getBoundingClientRect().top + window.scrollY + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            }
        };
        const handleScroll = () => {
            let found = false;
            for (const section of sections) {
                const element = document.getElementById(section);
                if (element) {
                    const position = element.getBoundingClientRect();
                    const offset = window.innerHeight * 0.2;

                    if (position.top <= window.innerHeight - offset && position.bottom >= offset) {
                        currentSection.value = section;
                        found = true;
                        break;
                    }
                }
            }
            if (!found) {
                currentSection.value = '';
            }
        };
        window.addEventListener('scroll', handleScroll);
        return { currentSection, sections, showNavbar, navigateToSection, sectionsMap };
    },
    methods: {
    changeLanguage(language) {
      TranslationService.changeLanguage(language);
      window.location.reload();
    }
  }
};
</script>
  
<style scoped>
.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 0;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 5%;
    padding-right: 5%;
}

.logo {
    font-size: 28px;
    font-family: "Typewriter Serial";
    margin: 0;
    padding: 0;
    font-weight: 600;
    letter-spacing: 1px;
}

.logo a {
    text-decoration: none;
    color: #fff;
}

.header-scrolled {
    background: rgba(0, 0, 0, 0.8);
    padding: 15px 3%;
}

.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar>ul>li {
    white-space: nowrap;
    padding: 0 15px 0 15px;
}

.navbar a,
.navbar a:focus {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-size: 16px;
    font-family: "Typewriter Serial";
    padding: 0;
    white-space: nowrap;
    transition: 0.3s;
    letter-spacing: 0.4px;
    position: relative;
    text-transform: uppercase;
}

.navbar>ul>li>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -6px;
    left: 0;
    width: 0;
    background-color: #fff;
    visibility: hidden;
    transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
    visibility: visible;
    width: 80%;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: #fff;
}

.language-select {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.language-select img {
    height: 30px;
    padding-left: 5px;
    cursor: pointer;
}
</style>
  